import { LocalStorageKey, SessionStorageKey } from "@constants/keys";
import { v4 as uuidv4 } from "uuid";

export type VisitId = string;

export const generateVisitId = (): void => {
  /**
   * Generates a VisitId that is used for all event payloads per 'load'.
   * VisitId will be stored in the localStorage.
   * VisitId is generated using the uuid module, specifically Version 4 was chosen as
   * it requires a completely random value.
   * (See - https://en.wikipedia.org/wiki/Universally_unique_identifier#Version_4_(random))
   *
   * @remarks
   * Creates the Visit ID that will be present for every 'load' so as to allow easy identification
   * for all events that happened per 'load' which is taken as the browsing session.
   */

  const visitId = uuidv4();
  clearOldVisitId();
  window.sessionStorage?.setItem(SessionStorageKey.VisitID, visitId);
};

export const getVisitId = (): string => {
  return window.sessionStorage?.getItem(SessionStorageKey.VisitID) ?? "";
};

const clearOldVisitId = () => {
  const oldVisitIdExists = window.localStorage.getItem(LocalStorageKey.VisitID);
  if (oldVisitIdExists) {
    window.localStorage.removeItem(LocalStorageKey.VisitID);
  }
};
