import { CreateEventOptions } from "@core/event";
import { Queuable } from "@core/queue";
import { applyHighwaySchema } from "@helper/helper";
import { CavaiAdsEventDataMapping, isCavaiEvent } from "./shared";

let _queue: Queuable;
let _options: CreateEventOptions;

function initialize(queue: Queuable, options: CreateEventOptions): void {
  window.addEventListener("message", handler);
  _queue = queue;
  _options = options;
}

function cleanup() {
  window.removeEventListener("message", handler);
}

export default {
  initialize,
  cleanup,
};

function handler(event: MessageEvent) {
  const data = event.data;
  if (isCavaiEvent(event.data) === false) {
    return;
  }

  const transformedEvent = applyHighwaySchema<
    "cavaiAds",
    CavaiAdsEventDataMapping
  >(
    {
      eventType: "cavaiAds",
      data,
    },
    _options,
  );

  _queue.push(transformedEvent);
}
