import { EventToTrackData } from "@browser/event";

export const getCookieFromPage = (args: EventToTrackData): string | null => {
  let cookieToReturn: string | null = null;
  document.cookie.split("; ").forEach((cookie) => {
    // Split the key-value pairs by the first occurrence of a '='
    const separatorIndex: number = cookie.indexOf("=");
    const cookieKey: string = cookie.slice(0, separatorIndex);
    let cookieValue: string = cookie.slice(separatorIndex + 1);

    // Checks for the last occurrence of '=' inside of the value portion
    if (cookieKey.trim() === args.identifier) {
      const lastSeparatorIndex: number = cookieValue.lastIndexOf("=");
      if (lastSeparatorIndex !== -1) {
        // In the presence of a '=' in the value portion, stitch the two parts together and return as a string
        cookieValue =
          cookieValue.slice(0, lastSeparatorIndex + 1) +
          encodeURIComponent(cookieValue.slice(lastSeparatorIndex + 1));
      }

      cookieToReturn = decodeURIComponent(cookieValue);
    }
  });
  return cookieToReturn;
};
