import { LocalStorageKey, SessionStorageKey } from "@constants/keys";
import { getCookieFromPage } from "@browser/plugins/ids/cookie";
import { getDeviceInformation } from "@browser/plugins/ids/device";
import { getUTMParameters } from "@browser/plugins/ids/utm";
import eventId from "@browser/plugins/ids/eventId";
import viewId from "@browser/plugins/ids/viewId";
import { getVisitId } from "@browser/plugins/ids/visitId";
import { getDataFromLocalStorage } from "@browser/plugins/ids/window";
import neuronConfig from "@config/config.json";
import neuronEnvironment from "@core/env";
import {
  CoreEventDataMapping,
  CreateEventOptions,
  TrackedEvent,
} from "@core/event";
import { ArticleFromFactory } from "./ArticleFromFactory";
import { DataLayer } from "./DataLayer";
import { SchemaTransformer } from "./ArticleFromBase";
import { CustomDatalayerEvents } from "@constants/events";
import { TrackArticleMetadata } from "@features/browser/trackArticleMetadata";
import { formatGsChannels } from "@helper/articleMetadataHelper";

const { neuronVersion, neuronCommitHash } = neuronEnvironment;

export type EventMetadataOptions = {
  platformType: Platform;
  onGenerateArticleMetadata?: () => EventArticleMetadata;
  onGetArticleMetadataFromDataLayer?: () => Record<string, unknown>;
};

export const isSameHost = (
  firstHostName: string,
  secondHostName: string,
): boolean => {
  return firstHostName === secondHostName;
};

function getField<T = string>(
  data: Record<string, unknown>,
  field: string,
): T | null {
  if (
    !data ||
    typeof data !== "object" ||
    Reflect.has(data, field) === false ||
    data[field] === undefined
  ) {
    return null;
  }

  return data[field] as T;
}

/**
 * This method retrieves the article metadata from window._data (GA3 architecture)
 * @returns {EventMetaData["article"]} - Returns the article metadata object
 */
export const dataSchemaTransformer = (
  data?: Record<string, unknown>,
): EventArticleMetadata => {
  const _data = data ?? {};
  return {
    adBlocker: getField(_data, "adblocker"),
    abVariant: getField(_data, "abVariant"),
    articleId: getField(_data, "articleid"),
    articleCount: getField(_data, "articlecount"),
    author: getField(_data, "author"),
    cueArticleId: getField(_data, "cue_articleid"),
    keyword: getField(_data, "keyword"),
    page: getField(_data, "page"),
    chapter1: getField(_data, "chapter1"),
    chapter2: getField(_data, "chapter2"),
    chapter3: getField(_data, "chapter3"),
    chapter1En: getField(_data, "chapter1_en"),
    chapter2En: getField(_data, "chapter2_en"),
    chapter3En: getField(_data, "chapter3_en"),
    gs_channels: formatGsChannels(
      getField(_data, "gs_channels") ?? getField(_data, "gpt_grapeshots"),
    ),
    level2: getField(_data, "level2"),
    level2Ga: getField(_data, "level2_ga"),
    level2Local: getField(_data, "level2_local"),
    contentCat: getField(_data, "contentcat"),
    contentType: getField(_data, "contenttype"),
    pubDate: getField(_data, "pubdate"),
    content_publication_utc:
      getField(_data, "content_publication_utc") ??
      getField(_data, "content_publication_date_utc"),
    content_last_updated_utc:
      getField(_data, "content_last_updated_utc") ??
      getField(_data, "content_update_date_utc"),
    title: getField(_data, "title"),
    titleGa: getField(_data, "title_ga"),
    visitorCat: getField(_data, "visitorcat"),
  };
};

// ArticleFromData is the default article metadata source.
// This change avoids breaking other parts of Neuron that
// might depend on the default behavior.
const articleFromData = ArticleFromFactory.createArticleFromData({
  schemaTransformer: dataSchemaTransformer,
});

type ArticleFromInstanceConfig =
  | {
      useDataLayer: true;
      dataLayer: DataLayer;
      schemaTransformer: SchemaTransformer<MagazineArticleMetadata>;
      virtualPageViewEvents: string[];
    }
  | {
      useDataLayer: false;
    };

export function initializeArticleFromInstance(
  config: ArticleFromInstanceConfig,
) {
  const { useDataLayer } = config;

  const articleFrom = useDataLayer
    ? ArticleFromFactory.createArticleFromDataLayer({
        dataLayer: config.dataLayer,
        schemaTransformer: config.schemaTransformer,
        eventPatterns: [
          CustomDatalayerEvents.PAGEVIEW_EVENT,
          ...config.virtualPageViewEvents,
        ],
      })
    : articleFromData;

  if (!articleFrom.setToLatest()) {
    console.warn("Neuron: No article metadata was found in the data layer");
  }

  const articleMetadataTracker = new TrackArticleMetadata({ articleFrom });
  articleMetadataTracker.initialize();

  return articleFrom;
}

export const generateEventMetadata = (
  options?: Partial<EventMetadataOptions>,
): EventMetaData => {
  const generateArticleMetadata =
    options?.onGenerateArticleMetadata ??
    (() => {
      return articleFromData.article;
    });
  const getDataLayer =
    options?.onGetArticleMetadataFromDataLayer ??
    (() => {
      return articleFromData.raw;
    });

  const platformType = options?.platformType ?? "script";

  return {
    currentWindowURL: window.location.href,
    neuronVersion: neuronVersion ?? neuronCommitHash ?? "version-unknown",
    platformType: platformType,
    identification: {
      suid: getCookieFromPage({
        dataLocation: "cookie",
        identifier: "suid",
      }),
      mysphw: getCookieFromPage({
        dataLocation: "cookie",
        identifier: "mysphw",
      }),
      visitId: getVisitId(),
      viewId: viewId.get(),
      permutiveId: getDataFromLocalStorage("permutive-id"),
      incomingNeuronId: getLinkDecoratedNeuronId(),
    },
    article: generateArticleMetadata(),
    utm: getUTMParameters(),
    device: getDeviceInformation(),
    dataLayer: getDataLayer(),
  };
};

function getLinkDecoratedNeuronId() {
  return window.sessionStorage.getItem(SessionStorageKey.IncomingNeuronId);
}

export const getUserInitialTimestamp = (): Date | null => {
  const initialTimestamp: string =
    window.sessionStorage.getItem(SessionStorageKey.StartTime) ?? "";
  if (initialTimestamp !== "") {
    return new Date(initialTimestamp);
  }

  return null;
};

export const persistInitialTimestamp = () => {
  // To remove old instances of _neuron.userStartTime in Local Storage.
  window.localStorage.removeItem(LocalStorageKey.StartTime);

  window.sessionStorage.setItem(
    SessionStorageKey.StartTime,
    new Date().toISOString(),
  );
};

export const removeInitialTimestamp = () => {
  window.sessionStorage.removeItem(SessionStorageKey.StartTime);
};

export const constructApiUrl = (url: string, token: string): string => {
  const params = new URLSearchParams();
  params.set("api-key", token);
  const endpoint = `${url}?${params}`;

  return endpoint;
};

export const getMappingFromDivID = (divId: string): string => {
  for (const key in neuronConfig.elementVisibleMapping) {
    if (divId.trim().toLowerCase().startsWith(key) && divId.includes(key)) {
      return neuronConfig.elementVisibleMapping[key];
    }
  }
  return "element";
};

export const getClickMappingFromElementID = (elementId: string): string => {
  for (const key in neuronConfig.adClicksMapping) {
    if (
      elementId.trim().toLowerCase().startsWith(key) &&
      elementId.includes(key)
    ) {
      return neuronConfig.adClicksMapping[key];
    }
  }
  return "element";
};

export const getElementFullDOMPath = (element: HTMLElement | null): string => {
  if (
    !element ||
    !element.parentNode ||
    element.tagName.toLowerCase() === "html"
  ) {
    return "html";
  }

  let selector = element.tagName.toLowerCase();
  // Check if there are siblings nodes
  const siblings =
    element.parentNode && Array.from(element.parentNode.children);
  // We will count the number of sibling tags there is for the given element and get the nth-child position
  const nthChild =
    siblings &&
    siblings
      .filter((sibling) => sibling.tagName === element?.tagName)
      .indexOf(element) + 1;
  // Only append :nth-child(index) if it's greater than :nth-child(1)
  if (nthChild && nthChild > 1) {
    selector += `:nth-child(${nthChild})`;
  }

  return `${getElementFullDOMPath(
    element.parentNode as HTMLElement,
  )} > ${selector}`;
};

export type CustomEventHandler<T = any> = (event: CustomEvent<T>) => void;

export function applyHighwaySchema<
  E extends keyof M,
  M extends CoreEventDataMapping = CoreEventDataMapping,
>(
  event: Pick<TrackedEvent<E, M>, "data" | "eventType">,
  options?: CreateEventOptions,
) {
  return {
    ...event,
    eventDateTime: new Date().toISOString(),
    eventId: eventId.create(),
    meta: generateEventMetadata(options?.metadataOptions),
  };
}

export const isBrowserSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export function someEventsMatchPatterns<E = Record<string, string>>(
  events: E[],
  patterns: string[],
  getEventName: (event: E) => string,
) {
  return events.some((event) =>
    patterns.some((pattern) =>
      new RegExp(pattern, "i").test(getEventName(event)),
    ),
  );
}
