import viewId from "@browser/plugins/ids/viewId";
import { LoadTrackerEvents, UnloadTrackerEvents } from "@constants/events";
import { CustomEventHandler } from "@helper/helper";

// --- types ---

export enum EVENTS {
  PAGES_PAGE_VIEW = "pages_page_view",
  ARTICLE_ARTICLE_VIEW = "article_article_view",
  ARTICLE_CLOSE = "article_close",
}

// --- private properties ----

let _handler: CustomEventHandler;

// --- public functions ---

function initialize(): void {
  _handler = createhandler();

  window.addEventListener(EVENTS.PAGES_PAGE_VIEW, _handler);
  window.addEventListener(EVENTS.ARTICLE_ARTICLE_VIEW, _handler);
  window.addEventListener(EVENTS.ARTICLE_CLOSE, _handler);
}

function cleanup() {
  window.removeEventListener(EVENTS.PAGES_PAGE_VIEW, _handler);
  window.removeEventListener(EVENTS.ARTICLE_ARTICLE_VIEW, _handler);
  window.removeEventListener(EVENTS.ARTICLE_CLOSE, _handler);
}

export default {
  initialize,
  cleanup,
};

// ---- private functions ----

function createhandler() {
  return () => {
    window.dispatchEvent(new CustomEvent(UnloadTrackerEvents.SEND));
    viewId.update();
    window.dispatchEvent(new CustomEvent(LoadTrackerEvents.SEND));
  };
}
