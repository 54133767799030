import { CavaiAdsEventData, CoreEventDataMapping } from "@core/event";

export type CavaiEvent = {
  action_intention: string;
  action_message: string;
  action_type: "click";
  campaign_topic: string;
  component_id: string;
  component_message: string;
  datetime: string;
  user_datetime: string;
  user_id: string;
  gam_advertiser_id?: string | null;
  gam_campaign_id?: string | null;
  gam_line_Item_id?: string | null;
};

export function isCavaiEvent(data: unknown): data is CavaiEvent {
  const cavaiEvent = data as CavaiEvent;
  let isValid = false;

  try {
    isValid =
      "action_intention" in cavaiEvent &&
      "action_message" in cavaiEvent &&
      "action_type" in cavaiEvent &&
      "campaign_topic" in cavaiEvent &&
      "component_id" in cavaiEvent &&
      "component_message" in cavaiEvent &&
      "datetime" in cavaiEvent &&
      "user_datetime" in cavaiEvent &&
      "user_id" in cavaiEvent;
  } catch (e) {
    return false;
  }

  return isValid;
}

export type CavaiAdsEventDataMapping = CoreEventDataMapping & {
  cavaiAds: CavaiEvent;
};

export type CavaiAdsBubbleEventDataMapping = CoreEventDataMapping & {
  cavaiAds: CavaiAdsEventData;
};
