import { CreateEventOptions, TrackedEvent } from "@core/event";
import eventId from "@browser/plugins/ids/eventId";
import { generateEventMetadata } from "@helper/helper";

function create(
  eventDataLayerType: string,
  eventData: object,
  options?: CreateEventOptions,
): TrackedEvent<`dataLayer.${typeof eventDataLayerType}`> {
  return {
    data: {
      ...eventData,
      dataLayerIdentifier: `${eventDataLayerType}`,
    },
    eventDateTime: new Date().toISOString(),
    eventType: `dataLayer.${eventDataLayerType}`,
    eventId: eventId.create(),
    meta: generateEventMetadata(options?.metadataOptions),
  };
}

export default {
  create,
};
