export const getUTMParameters = () => {
  const searchParams: URLSearchParams = new URLSearchParams(
    window.location.search,
  );

  const utmCampaign: string | null = searchParams.get("utm_campaign");
  const utmContent: string | null = searchParams.get("utm_content");
  const utmMedium: string | null = searchParams.get("utm_medium");
  const utmSource: string | null = searchParams.get("utm_source");
  const utmTerm: string | null = searchParams.get("utm_term");

  return {
    utmCampaign: utmCampaign,
    utmContent: utmContent,
    utmMedium: utmMedium,
    utmSource: utmSource,
    utmTerm: utmTerm,
  };
};
