import { v4 as uuidv4 } from "uuid";

let _view_id: string;

const get = (): string => _view_id;

const update = (): void => {
  /**
   * Generates a new ViewId when the page reloads or when the URL changes.
   * When the ViewId changes, a new unload event is sent.
   * This is used to track a user's journey down an infinite scroll page.
   */
  _view_id = uuidv4();
};

export default {
  get,
  update,
};
