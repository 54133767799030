import type {
  CreateEventOptions,
  TrackedEvent,
  UnloadEventData,
} from "@core/event";
import { generateEventMetadata, getUserInitialTimestamp } from "@helper/helper";
import eventId from "@browser/plugins/ids/eventId";
import { Queuable } from "@core/queue";

type UnloadEvent = TrackedEvent<"unload">;

function create(
  initialUserTimestamp: Date,
  options?: CreateEventOptions,
): UnloadEvent {
  const currentTimestamp = new Date(new Date().toISOString());

  const diffInSeconds = Math.floor(
    (currentTimestamp.valueOf() - initialUserTimestamp.valueOf()) / 1e3,
  );
  const diffInMilliseconds =
    currentTimestamp.valueOf() - initialUserTimestamp.valueOf();

  const unloadEventData: UnloadEventData = {
    browsingSessionDuration: diffInSeconds,
    browsingSessionDurationMilliseconds: diffInMilliseconds,
  };

  const unloadEvent: TrackedEvent<"unload"> = {
    data: unloadEventData,
    eventDateTime: new Date().toISOString(),
    eventType: "unload",
    eventId: eventId.create(),
    meta: generateEventMetadata(options?.metadataOptions),
  };

  return unloadEvent;
}

/**
 * Send an unload event to the queue if the user has an
 * initial timestamp. The initial timestamp is defined
 * when the page comes into focus, so if it's not defined,
 * then the user hasn't interacted with the page.
 */
function send(queue: Queuable, options?: CreateEventOptions) {
  const userInitialTimestamp = getUserInitialTimestamp();
  if (userInitialTimestamp) {
    const unloadEvent = create(userInitialTimestamp, options);
    queue.push(unloadEvent);
  }
}

export default {
  create,
  send,
};
