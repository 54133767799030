import { ArticleFromEvents } from "@constants/events";

type Config<SCHEMA extends EventArticleMetadata> = {
  articleFrom: ArticleFrom<SCHEMA>;
};

/**
 * This class is responsible for tracking changes to the article metadata
 * via a special custom event.
 */
export class TrackArticleMetadata<SCHEMA extends EventArticleMetadata> {
  private articleFrom: ArticleFrom<SCHEMA>;
  private boundHandler = this._handler.bind(this);

  constructor(config: Config<SCHEMA>) {
    this.articleFrom = config.articleFrom;
  }

  initialize() {
    window.addEventListener(ArticleFromEvents.SET_TO_LATEST, this.boundHandler);
  }

  cleanup() {
    window.removeEventListener(
      ArticleFromEvents.SET_TO_LATEST,
      this.boundHandler,
    );
  }

  private _handler(): void {
    if (this.articleFrom.setToLatest() === false) {
      console.warn(
        "Neuron (metadata tracker): failed to find article metadata.",
      );
    }
  }
}
