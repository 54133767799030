import { SessionStorageKey } from "@constants/keys";
import { removeToken } from "@browser/token";
import { getCookieWithID } from "@helper/cookie";
import neuronConfig from "@config/config.json";

import { v4 as uuidv4 } from "uuid";

/**
 * Returns a randomly-generated NeuronId that is used for subsequent event payloads.
 * NeuronId is generated using the uuid module, specifically Version 4 was chosen as
 * it requires a completely random value.
 * (See - https://en.wikipedia.org/wiki/Universally_unique_identifier#Version_4_(random))
 *
 * @remarks
 * Creates the Neuron Session ID that will tie all blocks of activities together
 * @returns A uniquely random generated NeuronId.
 */
export const getNeuronId = (): string => {
  const neuronIDFromCookie = getCookieWithID(neuronConfig.neuronId);
  if (!neuronIDFromCookie) {
    // If there is no cookie, we need to clear the current token to avoid mismatchs.
    removeToken();
    const neuronId = setNeuronId();
    return neuronId;
  }
  return neuronIDFromCookie;
};

export const setNeuronId = (incomingNeuronId?: string): string => {
  /**
   * Cookie expiration is currently set at 400 days from the current date.
   * This is with accordance to:
   * https://www.cookiestatus.com/#:~:text=Cookies%20restricted%20to%20a%20maximum%20lifetime%20of%20400%20days.
   *
   * Browsers set a hard expiry between 7 and 400 days. Hence, we are taking 400 days as the maximum now.
   */
  const todayDate = new Date();
  const futureDate = todayDate.setDate(
    todayDate.getDate() + neuronConfig.cookieExpirationDays,
  );

  const valueToSetForNeuronId: string =
    incomingNeuronId !== undefined && incomingNeuronId !== ""
      ? incomingNeuronId
      : uuidv4();
  document.cookie = `${neuronConfig.neuronId}=${encodeURIComponent(
    valueToSetForNeuronId,
  )}; expires=${new Date(futureDate).toUTCString()}; path=/;`;

  return valueToSetForNeuronId;
};

export const getLinkDecoratedNeuronId = (): string | null => {
  /**
   * Incoming NeuronId (_nei) from decorated outgoing links
   * would be stored in the Session Storage. This method
   * retrieves any incoming NeuronId that has been stored.
   */
  return window.sessionStorage.getItem(SessionStorageKey.IncomingNeuronId);
};
