export type SchemaTransformer<SCHEMA extends EventArticleMetadata> = (
  data?: Record<string, unknown>,
) => SCHEMA;

export type Config<SCHEMA extends EventArticleMetadata> = {
  schemaTransformer: SchemaTransformer<SCHEMA>;
};

export abstract class ArticleFromBase<SCHEMA extends EventArticleMetadata>
  implements ArticleFrom<SCHEMA>
{
  private _article: SCHEMA;
  private _raw: Record<string, unknown>;
  private readonly _schemaTransformer: SchemaTransformer<SCHEMA>;

  constructor(config: Config<SCHEMA>) {
    this._schemaTransformer = config.schemaTransformer;
    this._article = {} as SCHEMA;
    this._raw = {};
  }

  get article(): SCHEMA {
    return this._article;
  }

  get raw(): Record<string, unknown> {
    return this._raw;
  }

  setToLatest(): boolean {
    const data = this.seekLatest();
    if (!data) {
      return false;
    }

    this._article = this._schemaTransformer(data);
    this._raw = data;
    return true;
  }

  protected abstract seekLatest(): Record<string, unknown> | null;
}
