export abstract class EventQueue<EventType> implements Queuable {
  abstract push(...ev: EventType[]): void;
  abstract eventBuffer(): Array<EventType>;
  abstract flush(): void;
}

export interface Queuable {
  push: (...event: any[]) => void;
  flush: (beacon?: boolean) => void;
}
