import { LocalStorageKey } from "@constants/keys";
import { makeSessionClient } from "@core/api";

export type TokenValue = {
  token: string | "";
};

const clearOldSessionKey = (): void => {
  const oldSessionBufferKeyExists = window.localStorage.getItem(
    LocalStorageKey.OldSessionToken,
  );
  if (oldSessionBufferKeyExists) {
    window.localStorage.removeItem(LocalStorageKey.OldSessionToken);
  }
};

export const removeToken = (): void => {
  window.localStorage.removeItem(LocalStorageKey.SessionToken);
};

export const getToken = async (
  sessionApiUrl: string,
  sessionApiToken: string,
  platformType: Platform,
  neuronId?: string,
  refresh?: boolean,
): Promise<TokenValue> => {
  clearOldSessionKey();
  const existingToken = window.localStorage.getItem(
    LocalStorageKey.SessionToken,
  );
  if (existingToken == null || refresh) {
    /*
      If there is no token present, we would fetch a new one
      from the API using an initSession event.
    */
    const sessionClient = makeSessionClient(
      sessionApiUrl,
      sessionApiToken,
      platformType,
      neuronId,
    );
    return sessionClient.getToken().then((token) => {
      window.localStorage.setItem(LocalStorageKey.SessionToken, token.token);
      return token;
    });
  } else {
    const token: TokenValue = {
      token: existingToken,
    };
    return new Promise<TokenValue>((resolve) => resolve(token));
  }
};
