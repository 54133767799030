export class IntervalStartedError extends Error {
  constructor() {
    super("Interval must be stopped before starting again.");
  }
}

type IntervalCallback = (handler: TimerHandler, timeout: number) => number;
type ClearIntervalCallback = (intervalId: number) => void;
type IntervalOptions = {
  setInterval: IntervalCallback;
  clearInterval: ClearIntervalCallback;
};

export class Interval {
  private _intervalId: number | null = null;
  private readonly _setInterval: IntervalCallback =
    window.setInterval.bind(window);
  private readonly _clearInterval: ClearIntervalCallback =
    window.clearInterval.bind(window);

  constructor(options?: Partial<IntervalOptions>) {
    if (options?.setInterval) {
      this._setInterval = options.setInterval;
    }
    if (options?.clearInterval) {
      this._clearInterval = options.clearInterval;
    }
  }

  start(callback: TimerHandler, time: number) {
    if (this._intervalId) throw new IntervalStartedError();
    this._intervalId = this._setInterval(callback, time);
  }

  stop() {
    if (!this._intervalId) return;
    this._clearInterval(this._intervalId);
    this._intervalId = null;
  }

  get isRunning() {
    return this._intervalId !== null;
  }
}
