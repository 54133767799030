import { ArticleFromData } from "./ArticleFromData";
import { ArticleFromDataLayer } from "./ArticleFromDataLayer";

export class ArticleFromFactory {
  static createArticleFromDataLayer(
    config,
  ): ArticleFrom<MagazineArticleMetadata> {
    return new ArticleFromDataLayer(config);
  }
  static createArticleFromData(config): ArticleFrom<EventArticleMetadata> {
    return new ArticleFromData(config);
  }
}
