import { SessionStorageKey } from "@constants/keys";
import { getNeuronId, setNeuronId } from "@browser/plugins/ids/neuronId";
import { getCookieWithID } from "@helper/cookie";
import neuronConfig from "@config/config.json";
import { isSameHost } from "@helper/helper";

export const decorateOutgoingLinks = (domains: string[]) => {
  const whiteListedDomains: string[] = domains;
  const anchorElementsToQuery: string = whiteListedDomains
    .map((domain: string) => `a[href^="${domain}"]`)
    .join(",");

  const allAnchorElementsToDecorate: NodeListOf<HTMLAnchorElement> =
    document.querySelectorAll(anchorElementsToQuery);

  allAnchorElementsToDecorate.forEach((anchor: HTMLAnchorElement) => {
    const firstHostName = window.location.hostname;
    const secondHostName = new URL(anchor.href).hostname;
    if (!isSameHost(firstHostName, secondHostName)) {
      anchor.addEventListener("click", (event) => {
        event.preventDefault();

        const searchParams = new URLSearchParams(anchor.search);
        searchParams.append(neuronConfig.linkDecorationNeuronId, getNeuronId());

        const newURL: URL = new URL(
          `${anchor.protocol}//${anchor.host}${
            anchor.pathname
          }?${searchParams.toString()}`,
        );
        const targetContext: string = anchor.target ? anchor.target : "_self";
        (window as Window).open(newURL.toString(), targetContext);

        return false;
      });
    }
  });
};

/*
  This method checks if there is an incoming neuronId value which is represented by
  _nei. If there is, we will persist it and this would be used in subsequent event payloads.
*/
export const parseIncomingLink = () => {
  const searchParams: URLSearchParams = new URLSearchParams(
    window.location.search,
  );
  const incomingNeuronId = searchParams.get(
    neuronConfig.linkDecorationNeuronId,
  );
  if (incomingNeuronId) {
    const existingNeuronId = getCookieWithID(neuronConfig.neuronId);
    if (!existingNeuronId) {
      setNeuronId(incomingNeuronId);
    }

    window.sessionStorage.setItem(
      SessionStorageKey.IncomingNeuronId,
      incomingNeuronId,
    );
    searchParams.delete(neuronConfig.linkDecorationNeuronId);

    const updatedUrl = new URL(
      `${location.protocol}//${location.host}${location.pathname}`,
    );

    const query = searchParams.toString();
    updatedUrl.search = query;
    history.replaceState(null, "", updatedUrl);
  }
};
