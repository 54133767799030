import { CreateEventOptions, TrackedEvent } from "@core/event";
import { Queuable } from "@core/queue";
import {
  getUserInitialTimestamp,
  removeInitialTimestamp,
} from "@helper/helper";
import UnloadEvent from "@features/browser/events/UnloadEvent";
import { DataLayerTrackerEvents, UnloadTrackerEvents } from "@constants/events";

type TrackUnloadOptions = {
  createEventOptions: CreateEventOptions;
};

let _queue: Queuable;
let _createEventOptions: CreateEventOptions = {};

function initialize(queue: Queuable, options: Partial<TrackUnloadOptions>) {
  _queue = queue;
  if (options?.createEventOptions)
    _createEventOptions = options.createEventOptions;

  window.addEventListener("beforeunload", handler);
  window.addEventListener("blur", handler);

  // This event is used to send the unload event manually, for example,
  // when a user scrolls past the end of the article on a page with
  // infinite scroll.
  window.addEventListener(UnloadTrackerEvents.SEND, sendEventHandler);
}

function cleanup() {
  window.removeEventListener("beforeunload", handler);
  window.removeEventListener("blur", handler);
  window.removeEventListener(UnloadTrackerEvents.SEND, sendEventHandler);
}

export default {
  initialize,
  cleanup,
};

function handler() {
  window.dispatchEvent(new CustomEvent(DataLayerTrackerEvents.FLUSH));
  trackUnloadEvent({
    useBeacon: true,
  });
}

function sendEventHandler() {
  trackUnloadEvent();
}

function trackUnloadEvent(config?: Partial<{ useBeacon: boolean }>): void {
  const { useBeacon = false } = config ?? {};

  const initialUserTimestamp = getUserInitialTimestamp();
  if (!initialUserTimestamp) return;

  const unloadEvent: TrackedEvent<"unload"> = UnloadEvent.create(
    initialUserTimestamp,
    _createEventOptions,
  );

  _queue.push(unloadEvent);
  _queue.flush(useBeacon);
  removeInitialTimestamp();
}
