const MAX_ATTEMPTS = 3;
const ATTEMPT_INTERVAL = 250;

type GlobalObject = Window | typeof globalThis;
type Options = {
  maxAttempts?: number;
  attemptInterval?: number;
};

function isPropertyInGlobal(
  globalObject: GlobalObject,
  property: string,
  callback: (found: boolean) => void,
  options?: Options,
  attempts = 0,
) {
  const maxAttempts = options?.maxAttempts ?? MAX_ATTEMPTS;
  const attemptInterval = options?.attemptInterval ?? ATTEMPT_INTERVAL;

  if (globalObject[property]) {
    return callback(true);
  }

  if (attempts >= maxAttempts) {
    return callback(false);
  }

  setTimeout(
    () =>
      isPropertyInGlobal(
        globalObject,
        property,
        callback,
        options,
        attempts + 1,
      ),
    attemptInterval,
  );
}

/**
 * Attempts to retrieve a property from the global object. Sometimes properties
 * defined by other scripts may not be available immediately, so this function
 * will keep checking for the property until it is found.
 * @param globalObject
 * @param property
 * @returns
 */
export async function getPropertyFromGlobal<T = unknown>(
  globalObject: GlobalObject,
  property: string,
  options?: Options,
): Promise<T> {
  return new Promise((resolve, reject) => {
    isPropertyInGlobal(
      globalObject,
      property,
      (found) => {
        if (found) {
          resolve(globalObject[property] as T);
        } else {
          reject(new Error(`Property ${property} not found in global object`));
        }
      },
      options,
    );
  });
}
