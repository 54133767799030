import { initBrowser } from "@browser/index";

declare global {
  interface Window {
    Neuron?: Neuron;
    _data: unknown;
  }
}

export type Neuron = {
  init: typeof initBrowser;
};

if (window.Neuron) {
  console.warn(
    "Another instance of Neuron has already been initialised; check that you are not loading the SDK multiple times",
  );
} else {
  window.Neuron = { init: initBrowser };
}
