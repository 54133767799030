// Environment variables defined in esbuild.js must also
// be defined here so that the app can properly access them.
// Not all environments will properly assume the scope of the
// environment variables, so this is a necessary step.

const env = {
  neuronVersion:
    typeof _neuronVersion !== "undefined" ? _neuronVersion : undefined,
  neuronCommitHash:
    typeof _neuronCommitHash !== "undefined" ? _neuronCommitHash : undefined,
};

export default env;
