import { ArticleFromBase } from "./ArticleFromBase";

import { formatGsChannels } from "@helper/articleMetadataHelper";

function isRecord(value: unknown): value is Record<string, unknown> {
  return !!value && typeof value === "object";
}

export class ArticleFromData extends ArticleFromBase<EventArticleMetadata> {
  protected seekLatest(): Record<string, unknown> | null {
    if (Reflect.has(window, "_data") === false) {
      return null;
    }

    const data = Reflect.get(window, "_data");
    if (!isRecord(data)) {
      return null;
    }

    return {
      ...data,
      gs_channels: formatGsChannels(window.gs_channels),
      gpt_grapeshots: formatGsChannels(window.gpt_grapeshots),
    };
  }
}
