import { LocalStorageKey } from "@constants/keys";
import { BrowserTrackedEvent } from "@browser/event";
import { TokenValue } from "@browser/token";
import eventId from "@browser/plugins/ids/eventId";
import { getSiteId } from "@browser/plugins/ids/site";
import { constructApiUrl, generateEventMetadata } from "@helper/helper";

export type CollectorPostBody = {
  browserEvents: object[];
  token: string;
  publication: string;
  neuronId?: string;
};

export type SessionClient = {
  getToken: () => Promise<TokenValue>;
};

function isTokenValid(maybeToken: TokenValue): maybeToken is TokenValue {
  return maybeToken?.token !== undefined;
}

export const makeSessionClient = (
  url: string,
  apiToken: string,
  platformType: Platform,
  neuronId?: string,
): SessionClient => {
  const initSessionEvent: BrowserTrackedEvent<"initSession"> = {
    data: null,
    eventDateTime: new Date().toISOString(),
    eventType: "initSession",
    eventId: eventId.create(),
    meta: generateEventMetadata({ platformType: platformType }),
  };

  const initSessionPayload = {
    browserEvents: [initSessionEvent],
    neuronId: neuronId,
    publication: getSiteId(),
    token: null,
  };

  return {
    getToken: () => {
      const endpoint = constructApiUrl(url, apiToken);
      return fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(initSessionPayload),
      })
        .then((res) => res.json())
        .then((jsonResponse) => {
          const resJson = jsonResponse;
          if (isTokenValid(resJson)) {
            window.localStorage.setItem(
              LocalStorageKey.SessionToken,
              resJson.token,
            );
            return resJson;
          } else {
            return Promise.reject(new Error("Invalid Token"));
          }
        })
        .catch((error) => {
          console.error("Something went wrong getting the token from the API");
          return Promise.reject(error);
        });
    },
  };
};
