export const getCookieWithID = (cookieName: string): string | null => {
  let cookieToReturn: string | null = null;
  document.cookie.split("; ").forEach((cookie) => {
    const [key, value] = cookie.split("=");
    if (key.trim() === cookieName) {
      cookieToReturn = value;
    }
  });
  return cookieToReturn;
};
