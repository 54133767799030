import viewId from "@browser/plugins/ids/viewId";
import {
  ArticleFromEvents,
  DataLayerTrackerEvents,
  UnloadTrackerEvents,
} from "@constants/events";
import { DataLayer, GTMDataLayerEvent } from "@helper/DataLayer";
import {
  persistInitialTimestamp,
  someEventsMatchPatterns,
} from "@helper/helper";

type Config = {
  patterns: string[];
};

type Handler = (event: CustomEvent) => void;

/**
 * This function initializes the handler for updating the viewId when new events in the
 * dataLayer match the given pattern.
 *
 * The caller of this method is responsible for tearing down the event listener when it
 * is no longer needed.
 * @param {Config} config - The configuration object.
 * @returns {Handler} - The handler for the event listener.
 */
export function initViewIdOnNewDataLayerEventHandler(config: Config): Handler {
  const handler: Handler = (event: CustomEvent) =>
    onNewDataLayerEvent(event, config);
  window.addEventListener(DataLayerTrackerEvents.NEW_EVENTS, handler);
  return handler;
}

export function teardownViewIdOnNewDataLayerEventHandler(
  handler: Handler,
): void {
  window.removeEventListener(DataLayerTrackerEvents.NEW_EVENTS, handler);
}

function onNewDataLayerEvent(event: CustomEvent, config: Config): void {
  if (
    someEventsMatchPatterns<GTMDataLayerEvent>(
      event.detail.events,
      config.patterns,
      DataLayer.getEventName,
    )
  ) {
    window.dispatchEvent(new CustomEvent(UnloadTrackerEvents.SEND));
    persistInitialTimestamp(); // triggering an unload event removes the timestamp
    window.dispatchEvent(new CustomEvent(ArticleFromEvents.SET_TO_LATEST));
    viewId.update();
  }
}
