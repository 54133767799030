import { LoadTrackerEvents } from "@constants/events";
import { CreateEventOptions } from "@core/event";
import { Queuable } from "@core/queue";
import { applyHighwaySchema, CustomEventHandler } from "@helper/helper";
import { getUrl } from "@helper/location";
import { getReferrer } from "@helper/referrer";

let _handler: CustomEventHandler;

type Options = {
  createEventOptions: CreateEventOptions;
};

function initialize(queue: Queuable, options: Partial<Options>) {
  _handler = createHandler(queue, options);
  window.addEventListener(LoadTrackerEvents.SEND, _handler);
}

function cleanup() {
  window.removeEventListener(LoadTrackerEvents.SEND, _handler);
}

export default {
  initialize,
  cleanup,
};

function createHandler(queue: Queuable, options: Partial<Options>) {
  return (event: CustomEvent) => {
    const transformedEvent = applyHighwaySchema(
      {
        eventType: "load",
        data: {
          currentWindowUrl: getUrl(),
          referrer: getReferrer(),
          pageTitle: event.detail?.issue_name ?? "",
        },
      },
      options.createEventOptions,
    );

    queue.push(transformedEvent);
  };
}
