import { DataLayer } from "./DataLayer";
import { ArticleFromBase, type Config as ConfigBase } from "./ArticleFromBase";

type Config = ConfigBase<MagazineArticleMetadata> & {
  dataLayer: DataLayer;
  eventPatterns: string[];
};

/**
 * Retrieves article metadata from the data layer.
 * Please note that the schema transformer will expect you to return a default
 * schema if no data is provided.
 */
export class ArticleFromDataLayer extends ArticleFromBase<MagazineArticleMetadata> {
  private _dataLayer: DataLayer;
  private _eventPatterns: string[];

  constructor(config: Config) {
    super(config);
    this._dataLayer = config.dataLayer;
    this._eventPatterns = config.eventPatterns;
  }

  protected seekLatest(): Record<string, unknown> | null {
    return (
      this._dataLayer.events.findLast(
        (data) =>
          !!data &&
          typeof data === "object" &&
          !!data.event &&
          typeof data.event === "string" &&
          this._eventPatterns.includes(data.event),
      ) ?? null
    );
  }
}
