export type GTMDataLayerEvent = {
  [key: string]: unknown;
};

export type GTMDataLayer = GTMDataLayerEvent[];

const KEY__GTM__UNIQUE_EVENT_ID = "gtm.uniqueEventId";
const KEY__GTM__NAME = "event";

export class DataLayer {
  private readonly _dataLayer: GTMDataLayer;
  private cursor = 0;

  constructor(dataLayer: GTMDataLayer) {
    if (!Array.isArray(dataLayer)) {
      throw new Error("argument dataLayer must be an array");
    }

    this._dataLayer = dataLayer;
  }

  read(): GTMDataLayer {
    const events = this._dataLayer
      .filter((event) => DataLayer.getEventUniqueId(event) > this.cursor)
      .sort(
        (a, b) => DataLayer.getEventUniqueId(a) - DataLayer.getEventUniqueId(b),
      );
    this.cursor = events.length
      ? DataLayer.getEventUniqueId(events[events.length - 1])
      : this.cursor;
    return events;
  }

  get events(): GTMDataLayer {
    return this._dataLayer;
  }

  static getEventUniqueId(event: GTMDataLayerEvent): number {
    const eventId = event[KEY__GTM__UNIQUE_EVENT_ID];
    return eventId && typeof eventId === "number" ? eventId : 0;
  }

  static getEventName(event: GTMDataLayerEvent): string {
    return event[KEY__GTM__NAME] && typeof event[KEY__GTM__NAME] === "string"
      ? event[KEY__GTM__NAME]
      : "";
  }
}

export default {
  DataLayer,
};
